<template>
  <div v-loading="showLoading">
    <div class="type-box">
      <div
        v-for="(item, index) in [
          '现场管理',
          '文件落地',
          '考核细则',
          '内外审问题点',
        ]"
        :key="index"
        class="_TypeBox"
        :class="{
          action: typeBtnIndex == index,
        }"
        @click="changeTypeBtn(index)"
      >
        {{ item }}
      </div>
      <div class="_Select">
        <span>公司：</span>
        <el-select
          v-model="compy"
          clearable
          placeholder="请选择"
          @change="changCompy"
        >
          <el-option
            v-for="item in $store.state.companyList"
            :key="item.id"
            :label="item.companyName"
            :value="item.id"
          >
          </el-option>
        </el-select>
      </div>
      <div class="_Select">
        <span>年：</span>
        <el-date-picker
          @change="changYear"
          v-model="currentYear"
          format="yyyy"
          value-format="yyyy"
          type="year"
          placeholder="选择年"
        >
        </el-date-picker>
      </div>
    </div>
    <!-- 现场检查 -->
    <el-table
      id="table"
      v-if="typeBtnIndex == 0"
      :data="tableData.slice(start, end)"
      :height="elementHeight"
      style="width: 100%"
      @row-dblclick="rowDblclick1"
      @row-click="rowClick"
      :row-style="selectedstyle"
      :row-class-name="tableRowClassName"
      :header-cell-style="{
        background: '#EDF0F7',
        color: '#000000',
      }"
    >
      <el-table-column type="index" label="序号" width="55"> </el-table-column>
      <el-table-column label="公司" prop="compName"> </el-table-column>
      <el-table-column label="检查区域" prop="auditAreaName"> </el-table-column>
      <el-table-column label="备注" prop="remark"> </el-table-column>
      <!-- <el-table-column label="负责人" prop="chargeUserId"> </el-table-column> -->
      <el-table-column
        prop="titleCount"
        label="抽取问题数"
        show-overflow-tooltip
      >
      </el-table-column>
      <el-table-column prop="failCount" label="问题点数" show-overflow-tooltip>
      </el-table-column>
      <el-table-column prop="status" label="检查状态" show-overflow-tooltip>
        <template slot-scope="scope">
          <span class="status" v-show="scope.row.status === 0">
            <div class="statusbox"></div>
            未抽题
          </span>
          <span class="status" v-show="scope.row.status === 1"
            ><div style="background: #7fc7fa" class="statusbox"></div>
            已抽题</span
          >
          <span class="status" v-show="scope.row.status === 2"
            ><div class="statusbox1"></div>
            已完成</span
          >
          <span class="status" v-show="scope.row.status === 3"
            ><div class="statusbox"></div>
            整改完成</span
          >
          <span class="status" v-show="scope.row.status === 4"
            ><div style="background: #f2729c" class="statusbox"></div>
            未完成</span
          >
        </template>
      </el-table-column>
      <el-table-column
        prop="completeTime"
        label="完成时间"
        show-overflow-tooltip
      >
        <template slot-scope="scope">
          <span>{{ scope.row.completeTime | time }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="saveTime" label="创建时间" show-overflow-tooltip>
        <template slot-scope="scope">
          <span>{{ scope.row.saveTime | time }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="saveUserName" label="创建人" show-overflow-tooltip>
      </el-table-column>
    </el-table>

    <!-- 文件落地 -->
    <el-table
      v-if="typeBtnIndex == 1"
      :data="tableData.slice(start, end)"
      tooltip-effect="dark"
      style="width: 100%"
      :height="elementHeight"
      @row-dblclick="rowDblclick2"
      @row-click="rowClick"
      :row-style="selectedstyle"
      :row-class-name="tableRowClassName"
      :header-cell-style="{
        background: '#EDF0F7',
        color: '#000000',
      }"
    >
      <el-table-column type="selection" width="55"> </el-table-column>
      <el-table-column type="index" label="序号" width="55"> </el-table-column>
      <el-table-column label="岗位" prop="postName" width="120">
      </el-table-column>
      <el-table-column prop="companyName" label="所属公司" width="120">
      </el-table-column>

      <el-table-column
        label="抽取问题数"
        prop="titleCount"
        show-overflow-tooltip
      >
      </el-table-column>
      <el-table-column prop="failCount" label="问题点数" show-overflow-tooltip>
      </el-table-column>
      <el-table-column prop="status" label="检查状态" show-overflow-tooltip>
        <template slot-scope="scope">
          <span v-show="scope.row.status === 0">未抽题</span>
          <span v-show="scope.row.status === 1">已抽题</span>
          <span v-show="scope.row.status === 2">完成</span>
          <span v-show="scope.row.status === 3">整改完成</span>
          <span v-show="scope.row.status === 4">未完成</span>
        </template>
      </el-table-column>
      <el-table-column
        prop="completeTime"
        label="完成时间"
        show-overflow-tooltip
      >
        <template slot-scope="scope">
          <span>{{ scope.row.completeTime | time }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="saveTime" label="创建时间" show-overflow-tooltip>
        <template slot-scope="scope">
          <span>{{ scope.row.saveTime | time }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="saveUserName" label="创建人" show-overflow-tooltip>
      </el-table-column>
    </el-table>

    <!-- 考核细则 -->
    <el-table
      v-if="typeBtnIndex == 2"
      :data="tableData.slice(start, end)"
      style="width: 100%"
      :height="elementHeight"
      @row-dblclick="rowDblclick3"
      @row-click="rowClick"
      :row-style="selectedstyle"
      :row-class-name="tableRowClassName"
      :header-cell-style="{
        background: '#EDF0F7',
        color: '#000000',
      }"
    >
      <el-table-column type="selection" width="55"> </el-table-column>
      <el-table-column type="index" label="序号" width="55"> </el-table-column>
      <el-table-column label="岗位" prop="postName" width="120">
      </el-table-column>
      <el-table-column prop="companyName" label="所属公司" width="120">
      </el-table-column>

      <el-table-column
        label="抽取问题数"
        prop="titleCount"
        show-overflow-tooltip
      >
      </el-table-column>
      <el-table-column prop="failCount" label="问题点数" show-overflow-tooltip>
      </el-table-column>
      <el-table-column prop="status" label="检查状态" show-overflow-tooltip>
        <template slot-scope="scope">
          <span v-show="scope.row.status === 0">未抽题</span>
          <span v-show="scope.row.status === 1">已抽题</span>
          <span v-show="scope.row.status === 2">完成</span>
          <span v-show="scope.row.status === 3">整改完成</span>
          <span v-show="scope.row.status === 4">未完成</span>
        </template>
      </el-table-column>
      <el-table-column
        prop="completeTime"
        label="完成时间"
        show-overflow-tooltip
      >
        <template slot-scope="scope">
          <span>{{ scope.row.completeTime | time }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="saveTime" label="创建时间" show-overflow-tooltip>
        <template slot-scope="scope">
          <span>{{ scope.row.saveTime | time }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="saveUserName" label="创建人" show-overflow-tooltip>
      </el-table-column>
    </el-table>

    <!-- 内外审问题点 -->
    <el-table
      v-if="typeBtnIndex == 3"
      :data="tableData.slice(start, end)"
      :height="elementHeight"
      style="width: 100%"
      @row-click="rowClick"
      @row-dblclick="rowDblclick4"
      :row-style="selectedstyle"
      :row-class-name="tableRowClassName"
      :header-cell-style="{
        background: '#EDF0F7',
        color: '#000000',
      }"
    >
      <el-table-column type="selection" width="55"> </el-table-column>
      <el-table-column type="index" label="序号" width="55"> </el-table-column>
      <el-table-column label="类型" prop="auditType" width="120">
        <template slot-scope="scope">
          <span v-if="scope.row.auditType == 0">内审</span>
          <span v-if="scope.row.auditType == 1">外审</span>
        </template>
      </el-table-column>

      <el-table-column
        label="抽取问题数"
        prop="titleCount"
        show-overflow-tooltip
      >
      </el-table-column>
      <el-table-column prop="failCount" label="问题点数" show-overflow-tooltip>
      </el-table-column>
      <el-table-column prop="status" label="检查状态" show-overflow-tooltip>
        <template slot-scope="scope">
          <span class="status" v-show="scope.row.status === 0">
            <div class="statusbox"></div>
            未抽题
          </span>
          <span class="status" v-show="scope.row.status === 1">
            <div style="background: #7fc7fa" class="statusbox"></div>
            已抽题
          </span>
          <span class="status" v-show="scope.row.status === 2"
            ><div class="statusbox1"></div>
            已自纠</span
          >
          <span class="status" v-show="scope.row.status === 3"
            ><div class="statusbox"></div>
            整改完成</span
          >
          <span class="status" v-show="scope.row.status === 4"
            ><div style="background: #f2729c" class="statusbox"></div>
            自纠中</span
          >
        </template>
      </el-table-column>
      <el-table-column
        prop="completeTime"
        label="完成时间"
        show-overflow-tooltip
      >
        <template slot-scope="scope">
          <span>{{ scope.row.completeTime | time }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="saveTime" label="创建时间" show-overflow-tooltip>
        <template slot-scope="scope">
          <span>{{ scope.row.saveTime | time }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="saveUserName" label="创建人" show-overflow-tooltip>
      </el-table-column>
    </el-table>

    <el-pagination
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page.sync="currentPage"
      :page-sizes="[10, 20, 50]"
      :page-size="10"
      layout="total, sizes, prev, pager, next, jumper"
      :total="tableData.length"
      class="_Pagination"
    >
    </el-pagination>
  </div>
</template>

<script>
import { get } from "../../api/http";
export default {
  data() {
    return {
      elementHeight: 0,
      typeBtnIndex: 0,
      tableData: [],
      start: 0,
      end: 10,
      curSize: 10,
      currentPage: 1,
      tableRowIndex: null,
      currentYear: "",
      compy: "",
      showLoading: false,
    };
  },
  filters: {
    time: function (e) {
      if (e === null) {
        return "";
      }
      return e.split("T")[0];
    },
  },
  beforeRouteEnter(to, from, next) {
    if (from.name != "ControlDetial") {
      to.meta.ifDoFresh = true;
    }
    next();
  },
  activated() {
    if (this.$route.meta.ifDoFresh) {
      this.$route.meta.ifDoFresh = false;
      this.getTableList();
    } else {
      if (sessionStorage.getItem("controlPageRefsh")) {
        this.tableData.splice(this.tableRowIndex, 1);
        this.tableRowIndex = null;
        sessionStorage.removeItem("controlPageRefsh");
      }
    }
    this.getElementHeight();
  },
  mounted() {
    this.getElementHeight();
  },
  methods: {
    getElementHeight() {
      //innerHeight浏览器窗口的高度
      //offsetTop：距离上层控件的位置
      //最外层嵌套页面(顶部菜单)60,底(分页组件)52,div(main)的滚动条距顶部70
      this.$nextTick(() => {
        this.elementHeight =
          window.innerHeight -
          (document.getElementById("table").offsetTop + 60 + 52 + 15);
      });
    },
    changYear() {
      this.changeTypeBtn(this.typeBtnIndex);
    },
    changCompy() {
      this.changeTypeBtn(this.typeBtnIndex);
    },
    changeTypeBtn(index) {
      this.showLoading = true;
      this.tableRowIndex = null;
      this.typeBtnIndex = index;
      this.currentPage = 1;
      this.start = 0;
      this.tableData = [];
      this.getTableList();
    },
    // 分页
    handleSizeChange(e) {
      this.curSize = e;
      this.start = (this.currentPage - 1) * this.curSize;
      this.end = this.start + this.curSize;
    },
    handleCurrentChange(e) {
      this.tableRowIndex = null;
      this.start = (e - 1) * this.curSize;
      this.end = this.start + this.curSize;
    },
    // 获取列表
    getTableList() {
      this.currentYear = this.currentYear == null?'':this.currentYear
      var url = "";
      switch (this.typeBtnIndex) {
        case 0:
          url = "/api/Supervision/GetAreaList";
          break;
        case 1:
          url = "/api/Supervision/GetFileAuditList";
          break;
        case 2:
          url = "/api/Supervision/GetExamineRulesList";
          break;
        case 3:
          url = "/api/Supervision/GetInOutAuditList";
          break;
      }
      get(url + "?Year=" + this.currentYear + "&CompId=" + this.compy)
        .then((resp) => {
          if (resp.code == 200) {
            this.tableData = resp.data;
          }
          this.showLoading = false;
        })
        .catch(() => {
          this.$message({
            type: "error",
            message: "系统异常，请稍后再试",
          });
          this.showLoading = false;
        });
    },
    // 点击table
    tableRowClassName({ row, rowIndex }) {
      row.index = rowIndex;
    },
    selectedstyle({ rowIndex }) {
      if (this.tableRowIndex == rowIndex) {
        return {
          "background-color": "#E2E8FA !important",
          "font-weight": "bold !important",
          color: "#555555",
        };
      }
    },
    rowClick(row) {
      this.tableRowIndex = row.index;
    },
    rowDblclick1(row) {
      this.$router.push("/control-detail?examId=" + row.id + "&pageIndex=1");
    },
    rowDblclick2(row) {
      this.$router.push("/control-detail?examId=" + row.id + "&pageIndex=2");
    },
    rowDblclick3(row) {
      this.$router.push("/control-detail?examId=" + row.id + "&pageIndex=3");
    },
    rowDblclick4(row) {
      this.$router.push("/control-detail?examId=" + row.id + "&pageIndex=4");
    },
  },
};
</script>

<style lang="less" scoped>
.action {
  background: #5b79ae !important;
  color: #ffffff !important;
}
.type-box {
  display: flex;
  padding-bottom: 10px;
}
._Select > span {
  width: 60px !important;
}
._Pagination {
  background-color: white;
  text-align: end;
  padding: 10px 20px;
}
</style>